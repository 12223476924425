<template>
  <GeneralCategory
    :return-route="adminRoute"
    :category-name="categoryName"
    :rest-end-point="restEndPoint"
  />
</template>

<script>
import GeneralCategory from '../generalCategories/GeneralCategories'
import materialUseDefaults from './constants'

export default {
  name: 'AdminMaterialUseCategories',
  components: {
    GeneralCategory
  },
  data () {
    return { ...materialUseDefaults }
  }
}
</script>
